
// Vue reactivity
import { ref, reactive, defineComponent, watchEffect } from 'vue';

// icons
import { person, call, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, modalController, loadingController,
        IonGrid, IonCol, IonRow, } from '@ionic/vue';

// API services
import UserService from '@/services/UserService';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'UserProfileFormModal',
  props: {
    editingUser: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonGrid, IonCol, IonRow, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const user = reactive({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });

    watchEffect(() => {
      const { firstName, lastName, email, phone } = props.editingUser.value;
      user.firstName = firstName;
      user.lastName = lastName;
      //user.email = email;
      user.phone = phone;
    });

    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast } = utils();

    const closeModal = async (updatedUser: any = null) => {
      await modalController.dismiss({ updatedUser });
    };
    const updateUser = async() => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = await UserService.updateLoggedInUser(user.firstName, user.lastName, user.phone);
      loading.dismiss();
      presentToast( t('UserProfilePage.successUpdatePersonalInfo'), 2000 );
      closeModal(res);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      person, call, close,

      // variables
      user,

      // methods
      formatDate, closeModal, updateUser
    }
  }
});
